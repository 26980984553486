<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true"
			:showAdd="$hasAccess('report.profit/statisticsadd')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.tp" style="width: 120px;" clearable placeholder="场站特性">
					<el-option v-for="(item,index) in tpList" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.tp" style="width: 120px;" clearable placeholder="区域">
					<el-option v-for="(item,index) in $userArea" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="btime" title="统计时间" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{row.btime + ' -- ' + row.etime}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="other_income" title="第三方平台收入" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="platform_income" title="自有平台收入" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="all_income" title="总收入" align="center" min-width="50px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="electric_cost" title="电费支出" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="percentage_cost" title="平台抽成" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="area_cost" title="场地费" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="pro" title="利润率" align="center" min-width="50px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="ctime" title="创建时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="220px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('report.profit/statisticsedit')">编辑</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('report.profit/statisticsdel')">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog :title="(editDialogFormData.id>0?'编辑':'新增')" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="统计时间" prop="betime">
					<el-date-picker class="from-item-width" v-model="beginEnd" value-format="yyyy-MM-dd" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间" @change="date_change">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="第三方收入" prop="other_income">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2" v-model="editDialogFormData.other_income" class="input" size="medium">
					</el-input-number>元
				</el-form-item>
				<el-form-item label="电费支出" prop="electric_cost">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2" v-model="editDialogFormData.electric_cost" class="input" size="medium">
					</el-input-number> 元
				</el-form-item>	
				<el-form-item label="场地费" prop="area_cost">
					<el-input v-model="editDialogFormData.area_cost" class="input" style="width: 50%;" :disabled="true">
					</el-input> 元
				</el-form-item>	
				<el-form-item label="平台抽成" prop="percentage_cost">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2" v-model="editDialogFormData.percentage_cost" class="input" size="medium">
					</el-input-number> 元
				</el-form-item>	
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'report-profit-statisticslist',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '利润统计',
				isLoading: false,
				totalPage: 0,
				searchFormData: {
					group_id: this.$route.query.group_id
				},
				List: [],
				beginEnd: '',
				tpList: { //站点特性
					"1":"快充+慢充",
					"2":"快充",
					"3":"慢充"
				},
				groupList:[], //场站列表
				selecttime: {},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					user_type: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change'
					}],
					citys: [{
						required: true,
						message: '请选择区域',
						trigger: 'change'
					}],
					day_num: [{
						required: true,
						message: '请输入条件限制',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}],
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
		},
		activated() {
			//console.log("activated", this.$route.query.group_id == this.searchFormData.group_id);
			if(this.$route.query.group_id != this.searchFormData.group_id){
				this.searchFormData.group_id = this.$route.query.group_id;
				this.getList(1, 20);
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Report.Profit.StatisticsList(params);
				this.List = res.data
				for(let v of this.List){
					v.all_income = (Number(v.other_income) + Number(v.platform_income));
					v.pro = ((v.all_income - Number(v.electric_cost) - Number(v.percentage_cost) - Number(v.area_cost))/v.all_income*100).toFixed(1) + '%';
					v.all_income = v.all_income.toFixed(2)
				}
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {group_id: this.$route.query.group_id};
			},
			// 重置form
			resetFormData() {
				this.beginEnd = '';
				this.editDialogFormData = {group_id: this.$route.query.group_id};
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			editOne(row) {
				this.resetFormData();
				//row.city = row.city.toString();
				this.editDialogFormData = Object.assign({group_id: this.$route.query.group_id}, row);
				this.editDialogFormData['old_btime'] = this.editDialogFormData['btime'];
				this.beginEnd = [this.editDialogFormData['btime'], this.editDialogFormData['etime']];
				delete this.editDialogFormData._XID;
				this.editDialogShow = true;
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						group_id: row.group_id,
						btime: row.btime,
					}
					await this.$api.Report.Profit.StatisticsDel(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					//console.log('valid', valid)
					if (valid) {
						delete this.editDialogFormData.betime;
						delete this.editDialogFormData.all_income;
						delete this.editDialogFormData.pro;
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						if(this.editDialogFormData.old_btime){
							await this.$api.Report.Profit.StatisticsEdit(params);
						}else{
							await this.$api.Report.Profit.StatisticsAdd(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
					}
				})
			},
			date_change(e){ //日期选择
				console.log(e);
				this.editDialogFormData.btime = e[0];
				this.editDialogFormData.etime = e[1];
				this.editDialogFormData.area_cost = ((new Date(e[1])-new Date(e[0]))/24/3600/1000+1|0)*this.$route.query.area_cost/30|0;
				console.log("area_cost", this.editDialogFormData.area_cost, this.editDialogFormData.betime);
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
